<template>
    <el-card class="card" :bodyStyle="{ height: '100%' }">
        <div class="cardBody">
            <transition name="appMain" mode="out-in">
                <router-view />
            </transition>
        </div>
    </el-card>
</template>

<script>
    export default {
        name: "AddServicesMgt",
    };
</script>

<style>
    .card {
        height: calc(100% - 2px);
    }
    .cardBody {
        height: calc(100% - 40px);
    }

    .appMain-leave-active,
    .appMain-enter-active {
        transition: all 0.5s;
    }

    .appMain-enter {
        opacity: 0;
        transform: translateX(-30px);
    }

    .appMain-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }
</style>
